// assets
import {
    IconPlug,
    Icon24Hours,
    IconClockRecord,
    IconAntenna,
    IconChartHistogram,
    IconUsers,
    IconStatusChange,
    IconHeartRateMonitor,
    IconUsersGroup
} from '@tabler/icons-react';

// constant
const icons = {
    IconPlug,
    Icon24Hours,
    IconClockRecord,
    IconAntenna,
    IconChartHistogram,
    IconUsers,
    IconStatusChange,
    IconHeartRateMonitor,
    IconUsersGroup
};

// ==============================|| REALTIME MENU ITEMS ||============================== //

const parentClientSettings = {
    id: 'setting',
    title: 'Settings  [ ADMIN ONLY ]',
    type: 'group',
    children: [
        {
            id: 'setting-customers',
            title: 'Customers',
            type: 'item',
            url: '/settings/customerManagement/customers',
            icon: icons.IconUsersGroup,
            breadcrumbs: false
        },
        {
            id: 'setting-scadaScreens',
            title: 'Screens',
            type: 'item',
            url: '/settings/scada/screenconfig',
            icon: icons.IconHeartRateMonitor,
            breadcrumbs: false
        },
        {
            id: 'setting-user',
            title: 'Users',
            type: 'item',
            url: '/settings/userManagement/users',
            icon: icons.IconUsers,
            breadcrumbs: false
        }
    ]
};

export default parentClientSettings;
